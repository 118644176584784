@import "../../App.scss";

.about-hero {
    //padding-left: 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;  
    
    
    .about-top {
        //background: yellow;
        display: flex;
        width: 80%;
        height: 520px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        //margin-bottom: 250px;
        @include mobile {
            height: 400px;
            padding-top: 50px;
        }
        @include phones {
            padding-top: 10px;
        }

        a {
            width: 164px;
            height: 42px;
            display: inline-flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 200px;
            border: 1px solid var(--Linear, #66DAFF);
            background: #F8F7FA;
            color: #4A4752;
            text-align: center;
            font-family: "Inter", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%; /* 17.6px */
        }

        h2 {
            //background: green;
            width: 636px;
            color: #0F0D18;
            text-align: center;
            font-family: "Inter", sans-serif;
            font-size: 96px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 96px */
            letter-spacing: -3.84px;

            @include mobile {
                font-size: 66px;
                width: 100%;
            }

            @include phones {
                font-size: 56px;
                width: 100%;
            }
        }
    }

    .white-foot-head {
        height: 900px;
        background: #FFF;
        width: 100%;
    }

    // .white-footer {
    //     height: 400px !important;
    //     width: 100%;
    // }

}


@import "../App.scss";

.service-page {
    min-height: 100vh;
    background: #fff;
    mix-blend-mode: inherit; //luminosity;
    // background: linear-gradient(180deg, #7E5DEC 0%, rgba(126, 93, 236, 0.00) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;

    .bg-img {
        //background-image: url("../image/service-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        background: url("../image/About-BG.png"), lightgray 50% / cover no-repeat;

        .navbar {
            height: 150px;
            margin: 0px 96px;

            .wrapper {
                background: transparent;
                height: 100%;
                margin: 80px auto 0px auto;

                .top-nav {

                }
                .bottom-nav {
                    visibility: hidden;
                }
            }
            
        }
    }

    @include mobile {
        .white-navbar {
            margin: 0px 5%;

            .container .nav .contact-link {
                height: 40px;
            }

            .container .nav .home-link {
                width: 148.125px;
                height: 40px;
            }
        }
    }

    @include phones {
        .container .nav .contact-link {
                display: none;
        }
    }
    
    
    .white-footer {
        height: 400px!important;

        @include mobile {
            height: 550px!important;
        }
        @include phones {
            height: 700px!important;
        }

        .white-wrapper .foot .bg-link {
            @include mobile {
                gap: 9px;
            }
            @include phones {
                gap: 51px;
            }
            
        }

        .white-wrapper .foot .bg-link .socials {
            @include mobile {
                display: flex;
                width: 50%;
            }
            @include phones {
                width: unset;
            }
            
        }

        .white-wrapper .foot .bg-link .links {
            @include mobile {
                width: 70%;
                gap: 20px;
                display: flex;
                flex-direction: column;
                padding-right: 0%;
            }
            @include phones {
                padding-right: 8%;
                width: 100%;
            }

            a {
                @include mobile {
                    color: black;
                    text-align: justify;
                    font-family: "Inter", sans-serif;
                    font-size: 22px;
                }
                @include phones {
                    font-size: 32px;
                }
            }    
        }


    }
}


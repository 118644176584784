@import "../../App.scss";

.navbar {
    height: 281px;
   // background: blue;
    margin: 0px 96px;

    @include tablet {
        width: 100vw;
        margin: 0px 50px;
    }

    @include mobile {
        margin: 0px 10px;
        height: 200px;
    }
    @include phones {
        height: 200px;
    }

    .wrapper {
        height: 100%;
        padding-top: 59px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 57px;
        position: relative;
        //background: green;

        @include tablet {
            gap: 67px;
        }
    
        @include mobile {
            gap: 47px;
        }
        @include phones {
            height: 100%;
        }

        .top-nav {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            
           

            .home-link {
                width: 148.125px;
                height: 40px;
                cursor: pointer;

                @include mobile {
                    width: 100.125px;
                    height: 30px;
                }
                img {
                    width: 100%;
                    height: 100%
                }
            }

            .contact-link {
                width: 144px;
                height: 50px;
                position: relative;
                right: 70px;
                border-radius: 200px;
                border: 1px solid #7E5DEC;
                background: #7E5DEC;
                cursor: pointer;
                display: flex;
                padding: 16px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #FFFFFF;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%; /* 17.6px */

                @include tablet {
                    right: 200px;
                }
                @include mobile {
                    right: 100px;
                    height: 30px;
                    width: 100px;
                    font-size: 12px;
                    padding: 12px;
                }
                

            }
        }

        .bottom-nav {
            height: 80px;
            width: 100%;
            display: inline-flex;
            align-items: center;
            gap: 20px;
            white-space: nowrap;
            justify-content: center;
            border-radius: 200px;
            border: 1px solid var(--Linear, #66DAFF);
            border: 0px;
            @include tablet {
                gap: 12px;
                justify-content: flex-start;
            }

           

            button {
                height: 42px;
                display: flex;
                padding: 12px 12px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 200px;
                border: 1px solid var(--Linear, #66DAFF);
                background: #2C2738;
                color: #C8C3D5;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; /* 17.6px */
                @include tablet {
                    font-size: 9px;
                    padding: 12px;
                }

                @include mobile {
                    display: none;
                }
                @include phones {
                    display: none;
                }
            }
        }
  
    }
}




@import "../../App.scss";

.about-home-page {
    height: 550px;
    background: #0F0D18;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;


    .wrapper {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        width: 50%;
        margin-top: 60px;
        height: 447px;

        @include phones {
            width: 70%;
        }

        .top {
            display: flex;
            flex-direction: column;
            gap: 19px;
            // @include mobile {
            //     transform: translateX(150px) scale(1) translateZ(0px)!important;
            // }
            // @include phones {
            //     transform: translateX(10px) scale(1) translateZ(0px)!important;
            // }
            
            button {
                width: 154px;
                height: 42px;
                display: flex;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 200px;
                border: 1px solid var(--Linear, #66DAFF);
                background: #000;
                color: #FFF;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; /* 17.6px */
            }

            div {
                h3 {
                    color: #FFF;
                    font-family: "Inter", sans-serif;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%; /* 43.2px */
                    letter-spacing: -1.44px;
                }
            }
            
        }

        .center {
            width: 100%;

            @include tablet {
                width: 100%;
                height: 150px;
            }
            // @include mobile {
            //     transform: translateX(150px) scale(1) translateZ(0px)!important;
            // }
            // @include phones {
            //     transform: translateX(10px) scale(1) translateZ(0px)!important;
            // }

            p {
                width: 484px;
                color: #BCB8C6;
                font-family: "Inter", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 30px */
                color: #BCB8C6;
                font-family: "Inter", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 30px */

                @include tablet {
                    height: 100%;
                    width: 95%;
                    font-size: 16px;
                }
                @include mobile {
                    font-size: 10px;
                }
                @include phones {
                    font-size: 12px;
                }
            }
        }

        .bottom {
            width: 272px;   
            // @include mobile {
            //     transform: translateX(150px) scale(1) translateZ(0px)!important;
            // }
            // @include phones {
            //     transform: translateX(10px) scale(1) translateZ(0px)!important;
            // }         
            a {
                display: inline-flex;
                padding: 16px 16px 16px 32px;
                justify-content: center;
                align-items: center;
                gap: 32px;
                border-radius: 200px;
                border: 1px solid #E4E4E7;
                color: white;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%; /* 22px */
                letter-spacing: -0.4px;

                .svg {
                    display: flex;
                    padding: 12px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 200px;
                    cursor: pointer;
                    background: linear-gradient(98deg, #FC63FF -82.69%, #8E5BEC 47.23%, #66DAFF 194.91%);
                    
                    svg {
                        color: white;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}
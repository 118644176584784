@import "../App.scss";

.contact-page {
    min-height: 100vh;
    background: #fff;
    mix-blend-mode: inherit; //luminosity;
    // background: linear-gradient(180deg, #7E5DEC 0%, rgba(126, 93, 236, 0.00) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    .white-navbar {
        height: 180px;
        margin: 0px 5%;

        .container .nav 

        .container .nav .home-link {
            @include mobile {
                width: 100.125px;
                height: 30px;
            }
        }
        .container .nav .contact-link {
            display: none;
            // @include mobile {
            //     display: none;
            //     right: 80px;
            //     padding: 10px;
            //     width: 100px;
            // }
            // @include phones {
            //     width: 90px;
            // }
        }
    }

    .white-footer {
        height: 400px!important;

        @include mobile {
            height: 550px!important;
        }
        @include phones {
            height: 710px!important;
        }
        .white-wrapper .foot .bg-link {
            @include mobile {
                gap: 9px;
            }
            @include phones {
                gap: 51px;
            }
            
        }

        .white-wrapper .foot .bg-link .socials {
            @include mobile {
                display: flex;
                width: 50%;
            }
            @include phones {
                width: unset;
            }
            
        }

        .white-wrapper .foot .bg-link .links {
            @include mobile {
                width: 70%;
                gap: 20px;
                display: flex;
                flex-direction: column;
                padding-right: 0%;
            }
            @include phones {
                padding-right: 8%;
                width: 100%;
            }

            a {
                @include mobile {
                    color: black;
                    text-align: justify;
                    font-family: "Inter", sans-serif;
                    font-size: 22px;
                }
                @include phones {
                    font-size: 32px;
                }
            }    
        }


    }
}


@import '../../App.scss';

.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: black;

    .bg {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 400px;
        z-index: 999;
        //background-color: white;

        @include mobile {
            width: 300px!important;
            height: 320px!important;
        }

        @include phones {
            width: 250px!important;
            right: 45px!important;
        }

        .bg-link {
            width: 100%;
            //height: 100%;
            gap: 18px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 44px;

            @include mobile {
                height: 100%;
                width: 100%;
                padding: 22px 44px;
                gap: 10px;
            }

            @include phones {
                padding: 22px 22px;
            }

            .links {
                //position: absolute;
                width: 188px;
                height: 138px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                align-items: flex-start;
                gap: 12px;
    
                @include mobile {
                    height: 30%;
                    gap: 1px;
                }
    
                a {
                    color: #0B0A0F;
                    text-align: center;
                    font-family: "Inter", sans-serif;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%; /* 38.4px */
                    letter-spacing: -0.64px;
    
                    @include mobile {
                        font-size: 20px;
                    }
                }

                // a::after {
                //     content: "";
                //     position: absolute;
                //     bottom: -5px;
                //     left: 0;
                //     width: 100%;
                //     height: 1.5px;
                //     background: #7E5DEC;
                // }
               
            }

            .textContainer {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                width: 280px;
                @include mobile {
                    width: 220px;
                    height: 100px;
                    gap: 10px;
                }

                p {
                    color: #4A4752;
                    font-family: "Inter", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 21px */
                    letter-spacing: -0.28px;
                    @include mobile {
                        font-size: 12px;
                    }
                }
                p:nth-child(2) {
                    display: none; 
                }
            }

            .socials {
                width: 320px;
                height: 77px;
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;

                @include mobile {
                    width: 100%;
                    gap: 8px;
                    height: 57px;
                }

                a {
                    color: #BCB8C6;
                    font-family: "Inter", sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 21px */
                    letter-spacing: -0.28px;
                }

                div {
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;
                    a {
                        display: flex;
                        padding: 10px;
                        align-items: flex-start;
                        gap: 10px;
                        border-radius: 200px;
                        border: 1px solid #9797A6;
                        background: #FFF;

                        @include mobile {
                            padding: 0px;
                        }
                    }
                }
            }


        }
        
        
    }

    button {
        position: fixed;
        width: 71px;
        height: 50px;
        top: 60px;
        right: 85px;
        z-index: 999;
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        background-color: transparent;
        cursor: pointer;
        border-radius: 200px;
        border: 1px solid rgba(255, 255, 255, 0.40);

        @include tablet {
            right: 55px;
        }
    
        @include mobile {
            right: 25px;
            height: 35px;
            width: 60px; 
            top: 60px;
            padding: 16px 12px;
        }
        @include phones {
            top: 60px;
        }
    }
    button:hover {
        background-color: #7E5DEC;
    }
}



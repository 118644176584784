@import "../../App.scss";

.team {
    width: 100%;
    background: white;
    padding-top: 50px;
    

    .wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        //padding-left: 8%;
        //justify-content: center;

        .top {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            align-self: center;
            gap: 24px;
            width: 373px;
            height: 240px;

            @include mobile {
                width: 100%;
                padding: 0px 5%;
            }
            @include phones {
                height: 200px;
            }

            button {
                width: 125px;
                height: 42px;
                cursor: pointer;
                border-radius: 200px;
                border: 1px solid var(--Linear, #66DAFF);
                background: #F8F7FA;
                color: #4A4752;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; /* 17.6px */

                @include phones {

                }
            }
            h3 {
                width: 100%;
                color: #4A4752;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 48px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 57.6px */
                letter-spacing: -1.92px;
                @include phones {
                    font-size: 38px;
                }
            }
        }

        .middle {
            margin-top: 100px;
            display: inline-flex;
            //background: green;
            padding-left: 5%;
            flex-direction: column;
            align-items: flex-start;
            height: 820px;
            gap: 16px;
            width: 100%;
            margin-bottom: 100px;

            @include mobile {
                height: 100%;
            }

            .row {
                display: flex;
                align-items: flex-start;
                gap: 16px;

                @include tablet {
                    width: 95%;
                }
                @include mobile {
                    height: 250px;
                    //background: red;
                }
                @include phones {
                    flex-direction: column;
                    height: 900px;
                    //background: yellow;
                    align-items: center;
                }

                .item {
                    width: 405px;
                    height: 402px;
                    border-radius: 32px;
                    background: #0F0D18;
                    padding-top: 40px;
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    @include tablet {
                        width: 32%;
                    }
                    @include mobile {
                        height: 100%;
                        padding-top: 20px;
                    }
                    @include phones {
                        width: 70%;
                        height: 400px;
                    }

                    div {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 7px;
                        margin-left: 40px;
                        background: #0F0D18;
                        @include mobile {
                            margin-left: 5px;
                            width: 95%;
                        }
                        @include phones {
                            margin-left: 40px;
                            width: 60%;
                        }

                        h3 {
                            width: 129px;
                            color: #FFF;
                            font-family: "Inter", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 132%; /* 26.4px */
                            letter-spacing: -0.4px;
                        }
                        p{
                            width: 129px;
                            color: #BCB8C6;
                            font-family: "Inter", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%; /* 21px */
                            @include mobile {
                                font-size: 10px;
                            }
                            @include phones {
                                font-size: 12px;
                            }
                        }
                    }
                    
                    img {
                        position: absolute;
                        margin-top: 65px;
                        height: 280px;
                        padding-left: 110px;
                        border-radius: 30px;

                        @include tablet {
                            height: 200px;
                            width: 100%;
                            margin-top: 140px;
                            padding-left: 17px;
                        }
                        @include mobile {
                            height: 110px;
                            /* width: 150px; */
                            margin-top: 100px;
                            right: 0;
                            padding-left: 10%;
                        }
                        @include phones {
                            height: 136px;
                            width: 80%;
                            margin-top: 110px;
                            right: 5px;
                            padding-left: 0px;
                        }
                    }
  
                }
                .item-1 {
                    width: 405px;
                    height: 402px;
                    border-radius: 32px;
                    background: #0F0D18;
                    padding-top: 40px;
                    display: flex;
                    flex-direction: column;
                    position: relative;

                    @include tablet {
                        width: 32%;
                    }
                    @include mobile {
                        height: 100%;
                        padding-top: 20px;
                    }
                    @include phones {
                        width: 70%;
                        height: 400px;
                    }

                    div {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 7px;
                        margin-left: 40px;
                        background: #0F0D18;
                        @include mobile {
                            margin-left: 5px;
                            width: 95%;
                        }
                        @include phones {
                            margin-left: 40px;
                            width: 60%;
                        }

                        h3 {
                            width: 129px;
                            color: #FFF;
                            font-family: "Inter", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 132%; /* 26.4px */
                            letter-spacing: -0.4px;
                        }
                        p{
                            width: 129px;
                            color: #BCB8C6;
                            font-family: "Inter", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%; /* 21px */
                            @include mobile {
                                font-size: 10px;
                            }
                            @include phones {
                                font-size: 12px;
                                width: 60%;
                            }
                        }
                    }
                    
                    img {
                        position: absolute;
                        margin-top: 65px;
                        height: 280px;
                        /* padding-left: 90px; */
                        right: 10px;
                        border-radius: 30px;

                        @include tablet {
                            width: 100%;
                            margin-top: 140px;
                            padding-left: 50px;
                            height: 200px;
                        }
                        @include mobile {
                            margin-top: 100px;
                            height: 120px;
                            padding-left: 20%;
                        }
                        @include phones {
                            height: 130px;
                            width: 160px;
                            right: 20px;
                            margin-top: 125px;
                            padding-left: 0px;
                        }
                    }
  
                }
                .item-2 {
                    width: 405px;
                    height: 402px;
                    border-radius: 32px;
                    background: #0F0D18;
                    padding-top: 40px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    @include tablet {
                        width: 32%;
                    }
                    @include mobile {
                        height: 100%;
                        padding-top: 20px;
                    }
                    @include phones {
                        width: 70%;
                        height: 400px;
                    }

                    div {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 7px;
                        margin-left: 40px;
                        background: #0F0D18;
                        @include mobile {
                            margin-left: 5px;
                            width: 95%;
                        }
                        @include phones {
                            margin-left: 40px;
                            width: 60%;
                        }

                        h3 {
                            width: 129px;
                            color: #FFF;
                            font-family: "Inter", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 132%; /* 26.4px */
                            letter-spacing: -0.4px;
                        }
                        p{
                            width: 129px;
                            color: #BCB8C6;
                            font-family: "Inter", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%; /* 21px */
                            @include mobile {
                                font-size: 10px;
                            }
                            @include phones {
                                font-size: 12px;
                            }
                        }
                    }

                    img {
                        position: absolute;
                        margin-top: 80px;
                        border-radius: 30px;
                        height: 270px;
                        width: 380px;
                        right: 0;

                        @include tablet {
                            margin-top: 140px;
                            width: 100%;
                            height: 200px;  
                        }
                        @include mobile {
                            margin-top: 100px;
                            height: 110px;
                            width: 100%;
                        }
                        @include phones {
                            height: 150px;
                            width: 100%;
                            margin-top: 100px;
                            right: 5px;
                            padding-left: 40px;
                        }
                    }

                }
                
            }
            .row-2 {
                display: flex;
                align-items: flex-start;
                gap: 16px;

                @include tablet {
                    width: 95%;
                }
                @include mobile {
                    height: 250px;
                    //background: red;
                }
                @include phones {
                    flex-direction: column;
                    height: 900px;
                    //background: yellow;
                    align-items: center;
                }

                .item-3 {
                    width: 405px;
                    height: 402px;
                    border-radius: 32px;
                    background: #0F0D18;
                    padding-top: 40px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    @include tablet {
                        width: 32%;
                    }
                    @include mobile {
                        height: 100%;
                        padding-top: 20px;
                    }
                    @include phones {
                        width: 70%;
                        height: 298px;
                       // position: unset;
                    }

                    div {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 7px;
                        margin-left: 40px;
                        background: #0F0D18;
                        
                        @include mobile {
                            margin-left: 5px;
                            width: 95%;
                        }
                        @include phones {
                            margin-left: 40px;
                            border: transparent;
                            width: 60%;
                        }

                        h3 {
                            width: 129px;
                            color: #FFF;
                            font-family: "Inter", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 132%; /* 26.4px */
                            letter-spacing: -0.4px;
                        }
                        p{
                            width: 129px;
                            color: #BCB8C6;
                            font-family: "Inter", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%; /* 21px */
                            @include mobile {
                                font-size: 10px;
                            }
                            @include phones {
                                font-size: 12px;
                            }
                        }
                    }

                    img {
                        position: absolute;
                        margin-top: 100px;
                        height: 240px;
                        width: 100%;
                        border-radius: 30px;
                        right: 0;
                        @include tablet {
                            margin-top: 130px;
                            border-radius: 30px;
                            height: 200px;
                        }
                        @include mobile {
                            width: 100%;
                            margin-top: 120px;
                            height: 100px;
                        }
                        @include phones {
                            border-radius: 45px!important;
                            height: 200px;
                            width: 150px;
                            right: 15px;
                            margin-top: 85px;
                            padding-left: 10px;
                        }
                    }

                }
                .item-4 {
                    width: 405px;
                    height: 402px;
                    border-radius: 32px;
                    background: #0F0D18;
                    padding-top: 40px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    background: #0F0D18;

                    @include tablet {
                        width: 32%;
                    }
                    @include mobile {
                        height: 100%;
                        padding-top: 20px;
                    }
                    @include phones {
                        width: 70%;
                        height: 298px;
                    
                    }

                    div {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 7px;
                        margin-left: 40px;
                        @include mobile {
                            margin-left: 5px;
                            width: 95%;
                        }
                        @include phones {
                            margin-left: 40px;
                            border: transparent;
                            width: 60%;
                        }

                        h3 {
                            width: 129px;
                            color: #FFF;
                            font-family: "Inter", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 132%; /* 26.4px */
                            letter-spacing: -0.4px;
                        }
                        p{
                            width: 129px;
                            color: #BCB8C6;
                            font-family: "Inter", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%; /* 21px */
                            @include mobile {
                                font-size: 10px;
                            }
                            @include phones {
                                font-size: 12px;
                            }
                        }
                    }

                    img {
                        position: absolute;
                        height: 250px;
                        margin-top: 95px;
                        width: 100%;
                        border-radius: 30px;
                        right: 0;
                        @include tablet {
                            margin-top: 140px;
                            border-radius: 30px;
                            height: 200px;
                        }
                        @include mobile {
                            width: 100%;
                            height: 120px;
                            margin-top: 100px;
                        }
                        @include phones {
                            height: 180px;
                            width: 180px;
                            right: 10px;
                            padding-left: 10px;
                            margin-top: 80px;
                        }
                    }

                }
                .item-5 {
                    width: 405px;
                    height: 402px;
                    border-radius: 32px;
                    background: #0F0D18;
                    padding-top: 40px;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    background: #0F0D18;

                    @include tablet {
                        width: 32%;
                    }
                    @include mobile {
                        height: 100%;
                        padding-top: 20px;
                    }
                    @include phones {
                        width: 70%;
                        height: 298px;
                    
                    }

                    div {
                        display: inline-flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 7px;
                        margin-left: 40px;
                        @include mobile {
                            margin-left: 5px;
                            width: 95%;
                        }
                        @include phones {
                            margin-left: 40px;
                            border: transparent;
                            width: 60%;
                        }

                        h3 {
                            width: 129px;
                            color: #FFF;
                            font-family: "Inter", sans-serif;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 132%; /* 26.4px */
                            letter-spacing: -0.4px;
                            @include mobile {
                                font-size: 18px;
                            }
                        }
                        p{
                            width: 129px;
                            color: #BCB8C6;
                            font-family: "Inter", sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%; /* 21px */
                            @include mobile {
                                font-size: 10px;
                            }
                            @include phones {
                                font-size: 12px;
                            }
                        }
                    }

                    img {
                        position: absolute;
                        height: 280px;
                        margin-top: 55px;
                        width: 75%;
                        border-radius: 30px;
                        right: 0;
                        @include tablet {
                            margin-top: 140px;
                            border-radius: 30px;
                            height: 200px;
                        }
                        @include mobile {
                            width: 80%;
                            height: 120px;
                            margin-top: 100px;
                        }
                        @include phones {
                            height: 170px;
                            width: 180px;
                            right: 10px;
                            padding-left: 10px;
                            margin-top: 90px;
                        }
                    }

                }
            }
        }
    }
}
@import "../../App.scss";

.mission {
    width: 100%;
    background: white;
    padding-left: 8%;
    

    .wrapper {
        width: 90%;
        height: 595px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        border-radius: 48px;
        background: #0F0D18;
        margin-top: 100px;

        @include mobile {
            width: 90%;
            height: 750px;
            flex-direction: column;
            align-items: center;
            padding-top: 0px;
        }
    }

    .left {
        width: 50%;
        flex-shrink: 0;
       // background: red;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include tablet {
            width: 50%;
            align-items: center;
        }

        @include mobile {
            width: 50%;
            height: 350px;
            margin-top: -80px;
            //background: red;
            align-items: center;
        }

        @include phones {
            width: 80%;
            height: 270px;
            margin-top: -50px;
        }

        
        img {
            width: 390px;
            height: 468px;
            margin-top: -25px;

            @include tablet {
                margin-top: 0px;
                width: 90%;
            }

            @include mobile {
                height: 100%;
            }
        }
    }

    .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 39px;

        @include mobile {
            width: 70%;
            gap: 10px;
        }
        @include phones {
            gap: 20px;
        }

        
        
        .top {
            width: 500px;
            height: 150px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 19px;
            margin-top: 110px;
            //background: green;

            @include tablet {
                width: 100%;
                height: 200px;
            }

            @include mobile {
                margin-top: 0px;
                height: 200px;
                gap: 10px;
            }
            @include phones {
                gap: 15px;
            }

          
         
            button {
                display: flex;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 200px;
                border: 1px solid var(--Linear, #66DAFF);
                background: #000;
                color: #FFF;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; /* 17.6px */
            }
            h3 {
                width: 90%;
                align-self: stretch;
                color: #FFF;
                font-family: "Inter", sans-serif;
                font-size: 36px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 43.2px */
                letter-spacing: -1.44px;
                @include mobile {
                    font-size: 28px;
                }
                @include phones {
                    font-size: 25px;
                }
            }
            div {
                width: 133px;
                height: 133px;
                margin-right: 112px;
                flex-shrink: 0;
                align-self: flex-end;
                position: absolute;

                img {
                    width: 100%;
                }
            }
        }
        p {
            width: 90%;
            align-self: stretch;
            color: #BCB8C6;
            font-family: "Inter", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 30px */

            @include tablet {
                width: 80%;
                font-size: 18px;
            }
            @include mobile {
                width: 100%;
                font-size: 14px;
            }

            @include phones {
                font-size: 12px;
            }

         
        }
    }

}


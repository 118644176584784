@import "../../App.scss";

.footer {
    height: 600px;
    padding: 240px 10px 0px 8%;

    @include mobile {
        height: 820px;
        padding: 240px 5% 0px 5%;
    }
    @include phones {
        height: 850px;
    }
    
    .wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        //background: greenyellow;
        
        img {
            width: 148.125px;
            height: 40px;
            flex-shrink: 0;
            //background: lightgray 50% / cover no-repeat;
            @include mobile {
                align-self: center;
            }
        }

        .foot {
            height: 90%;
            padding-top: 67px;


            @include mobile {
                height: 100%;
            }

            .bg-link {
                height: 80%;
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                gap: 121px;
                @include tablet {
                    height: 100%;
                    gap: 40px;
                }

                @include mobile {
                    height: 100%;
                    gap: 40px;
                    flex-direction: column-reverse;
                }

                .socials {
                    display: flex;
                    width: 195px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;

                    @include mobile {
                        width: 100%;
                        align-items: center;
                    }
                    @include phones {
                        align-items: center;
                    }

                    a {
                        color: #BCB8C6;
                        font-family: "Inter", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                        letter-spacing: -0.32px;
                    }

                    div {
                        display: flex;
                        align-items: flex-start;
                        align-content: flex-start;
                        gap: 16px;
                        align-self: stretch;
                        flex-wrap: wrap;

                        @include mobile {
                            gap: 20px;
                            align-self: center;
                        }
                        @include phones {
                            align-items: center;
                            align-self: unset;
                        }

                        a {
                            display: flex;
                            padding: 12px;
                            align-items: flex-start;
                            gap: 10px;
                            border-radius: 200px;
                            border: 1px solid #4A4752;
                            
                        }
                    }
                }

                .textContainer {
                    width: 500px;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;

                    @include mobile {
                        width: 100%;
                        display: inline-flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 5px;
                        text-align: center;
                    }
                    @include phones {
                        align-items: unset;
                        text-align: center;
                    }
                
                    p {
                        color: #BCB8C6;
                        font-family: "Inter", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */  
                        @include mobile {
                            font-size: 14px;
                        }
                    }
                }

                .links {
                    width: 50%;
                    //background: yellow;
                    gap: 20px;
                    display: flex;
                    flex-direction: column;
                    //align-items: center;
                    padding-right: 8%;
                    @include tablet {
                        padding-right: 2%;
                        align-items: baseline;
                    }

                    @include mobile {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    

                    a {
                        color: #FFF;
                        text-align: justify;
                        font-family: "Inter", sans-serif;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; /* 38.4px */
                        letter-spacing: -0.64px;
                        display: flex;
                        justify-content: flex-end;

                        @include mobile {font-size: 32px;}
                    }
                }

                

                
            }
        }
    }
}
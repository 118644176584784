@import "../../App.scss";

.white-navbar {
    height: 180px;
    margin: 0px 96px;

    .container {
        height: 100%;
        padding-top: 59px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 97px;
        //background: green;

        .nav {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .home-link {
                width: 148.125px;
                height: 40px;
                margin-left: 2%;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%
                }
            }

            .contact-link {
                width: 144px;
                height: 50px;
                position: relative;
                right: 70px;
                border-radius: 200px;
                border: 1px solid #7E5DEC;
                background: #7E5DEC;
                cursor: pointer;
                display: flex;
                padding: 16px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: #FFFFFF;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%; /* 17.6px */

             
                @include tablet {
                    right: 50px;
                }
                @include mobile {
                    right: 100px;
                    height: 40px;
                    width: 100px;
                    font-size: 12px;
                    padding: 12px;
                }

                @include phones {
                    display: none;
                }
            }
        }

       
    }
}




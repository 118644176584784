@import "../../App.scss";

.service-home-page {
    height: 900px;
    background: #0F0D18;

    .wrapper {
        width: 90%;
        height: 775px;
        margin: 100px auto;
        padding: 30px 30px 30px 30px;
        border-radius: 48px;
        background: #FFF;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;


        .top {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            width: 343px;
            height: 138px;
            position: relative;

            a {
                display: flex;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 200px;
                border: 1px solid var(--Linear, #66DAFF);
                background: #F8F7FA;
                color: #4A4752;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; /* 17.6px */
             }

             p {
               // width: 373px;
                color: #4A4752;
                font-family: "Inter", sans-serif;
                font-size: 36px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 36px */
                letter-spacing: -1.44px;

                @include phones {
                    font-size: 25px;
                    width: 60%;
                }
             }
        }

        .middle {
            height: 400px;
            //background: yellowgreen;
            position: relative;
            top: -40px;
            
            
        }


        .bottom {
            width: 272px;
            margin-top: 0px;
            align-self: center;
            
            a {
                display: inline-flex;
                padding: 16px 16px 16px 32px;
                justify-content: center;
                align-items: center;
                gap: 32px;
                border-radius: 200px;
                border: 1px solid #E4E4E7;
                color: #262641;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%; /* 22px */
                letter-spacing: -0.4px;
                @include phones {
                    gap: 20px;
                }

                .svg {
                    display: flex;
                    padding: 12px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 200px;
                    cursor: pointer;
                    background: linear-gradient(98deg, #FC63FF -82.69%, #8E5BEC 47.23%, #66DAFF 194.91%);
                    
                    svg {
                        color: white;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
                        
                
        }
            
    }
            
        

        

}







@import "../../App.scss";

.white-footer {
    height: 400px;
    width: 100%;
    padding: 10px 10px 0px 8%;
    background: #FFF;
    @include mobile {
        height: 580px;
    }
    @include phones {
        height: 690px;
        padding: 40px 5% 0px 5%;
    }
    
    .white-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
       
        img {
            width: 148.125px;
            height: 40px;
            flex-shrink: 0;
            //background: lightgray 50% / cover no-repeat;
            @include mobile {
                align-self: center;
            }
        }

        .foot {
            height: 90%;
            padding-top: 67px;
            @include mobile {
                height: 100%;
            }

            .bg-link {
                height: 80%;
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                gap: 121px;
                @include tablet {
                    height: 100%;
                    gap: 40px;
                }
                @include mobile {
                    height: 100%;
                    gap: 40px;
                    flex-direction: column-reverse;
                    align-items: center;
                }

                .socials {
                    display: flex;
                    width: 195px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    @include mobile {
                        width: 100%;
                        align-items: center;
                    }
                    @include phones {
                        align-items: center;
                    }

                    a {
                        color: #BCB8C6;
                        font-family: "Inter", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                        letter-spacing: -0.32px;
                    }

                    div {
                        display: flex;
                        align-items: flex-start;
                        align-content: flex-start;
                        gap: 36px;
                        align-self: stretch;
                        flex-wrap: wrap;
                        @include mobile {
                            display: flex;
                            gap: 18px;
                            align-self: center;
                        }
                        @include phones {
                            gap: 20px;
                           
                        }

                        a {
                            display: flex;
                            padding: 12px;
                            align-items: flex-start;
                            gap: 10px;
                            border-radius: 200px;
                            border: 1px solid #4A4752;
                            width: 46px; 
                            @include mobile {
                                width: 42px;
                                height: 42px;
                            }
                            @include phones {
                                width: 46px;
                                height: 46px;
                            }
                        }
                    }
                }

                .textContainer {
                    width: 500px;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    @include mobile {
                        width: 100%;
                        align-items: center;
                    }
                    @include phones {
                        width: 100%;
                        display: inline-flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 5px;
                    }
                
                    p {
                        color: #BCB8C6;
                        font-family: "Inter", sans-serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                        @include mobile {
                            text-align: center;
                        }  
                    }
                   
                }

                .links {
                    width: 50%;
                    //background: yellow;
                    gap: 20px;
                    display: flex;
                    flex-direction: column;
                    padding-right: 8%;
                    @include tablet {
                        padding-right: 2%;
                        align-items: baseline;
                    }
                    @include mobile {
                        width: 70%;
                        padding-right: 0%;
                        align-items: center;
                    }
                    @include phones {
                        width: 100%;
                        padding-right: 8%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    

                    a {
                        color: black;
                        text-align: justify;
                        font-family: "Inter", sans-serif;
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; /* 38.4px */
                        letter-spacing: -0.64px;
                        display: flex;
                        justify-content: flex-end;
                        @include mobile {
                            font-size: 18px;
                            justify-content: flex-start;
                        }
                        @include phones {
                            font-size: 32px;
                            justify-content: flex-end;
                        }
                    }
                }

                

                
            }
        }
    }
}
@import "../../App.scss";

.service-list {
    padding: 50px 24px;
    background: white;

    @include mobile {
        padding: 10px 24px;
    }

    .container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .container-top {
            display: inline-flex;
            padding: 82px 72px 123px 72px;
            flex-direction: column;
            align-items: flex-start;
            gap: 103px;
            border-radius: 48px;
            background: #F1F0F5;

            @include mobile {
                padding: 82px 40px 123px 40px;
                gap: 50px;
            }

            @include phones {
                gap: 20px;
                padding: 25px;
            }

            .top {
                height: 300px;
                width: 100%;
                @include phones {
                    height: 250px;
                }
    
                p {
                    height: 100%;
                    color: #0A0816;
                    font-family: "Inter", sans-serif;
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 50.4px */
                    letter-spacing: -0.72px;

                    @include tablet {
                        font-size: 30px;
                    }
                    @include mobile {
                        font-size: 20px;
                    }
                    @include phones {
                        font-size: 16px;
                    }
                }
            }

            .s-list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                //background: yellow;
                //align-self: flex-start;
                @include tablet {
                    width: 100%;
                }
    
                .row {
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;
                    @include tablet {
                    width: 100%;
                    }
                    @include mobile {
                        flex-direction: column;
                        align-items: center;
                    }
                }
    
                .list {
                    display: flex;
                    padding: 50px 80px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 56px;
                    border-radius: 24px;
                    background: #FFF;
                    width: 550px;
                    height: 467px;
                    @include tablet {
                        width: 50%;
                        padding: 30px;
                        gap: 26px;
                        justify-content: flex-start;
                    }
                    @include mobile {
                        width: 300px;
                        height: 300px;
                        padding: 20px;
                        gap: 0px;
                        justify-content: flex-start;
                    }
                    @include phones {
                        width: 100%;
                    }
    
                    div {
                        width: 115px;
                        height: 105px;
                        @include mobile {
                            padding: 20px;
                            width: 80px;
                            height: 70px;
                        }
                        @include phones {
    
                        }

                        img {
                            width: 100%;
                            height: 100%;
                        }
    
                    }
                    .textContainer {
                        display: flex;
                        width: 456px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 24px;
                        @include tablet {
                            width: 100%;
                        }
                        @include mobile {
                            width: 100%;
                            display: flex;
                            gap: 14px;
                            height: 200px;
                            padding-bottom: 1px;
                            //background: green;
                        }
                        @include phones {
                           padding-left: 5px;
                           padding-right: 5px;
                        }
    
                        h3 {
                            align-self: stretch;
                            color: #0F0D18;
                            font-family: "Inter", sans-serif;
                            font-size: 36px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 50.4px */
                            letter-spacing: -0.72px;
                            @include tablet {
                                font-size: 30px;
                            }
                            @include mobile {
                                font-size: 24px;
                            }
                        }
                        p {
                            align-self: stretch;
                            color: #262641;
                            font-family: "Inter", sans-serif;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 150%; /* 24px */
                            @include mobile {
                                width: 100%;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }


        }

        
        .container-bottom {
            padding: 188px 202px 188px 0px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            @include mobile {
                padding: 50px 50px 50px 50px;
            }
            @include mobile {
                padding: 50px 5%;
            }

            p {
                width: 721px;
                color: #0A0816;
                font-family: "Inter", sans-serif;
                font-size: 36px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 50.4px */
                letter-spacing: -0.72px;
                @include mobile {
                    font-size: 25px;
                }
                @include phones {
                    font-size: 23px;
                }
            }
        }
    }

    
}


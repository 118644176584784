@import "../App.scss";

.about-page {
    min-height: 100vh;
    background: #fff;
    mix-blend-mode: inherit; //luminosity;
    // background: linear-gradient(180deg, #7E5DEC 0%, rgba(126, 93, 236, 0.00) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;


    .bg-img {
        //background-image: url("../image/service-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        background: url("../image/About-BG.png"), lightgray 50% / cover no-repeat;

        .white-navbar {
            height: 180px;
            margin: 0px 96px;

            @include mobile {
                margin: 0px 10px;
            }

            @include phones {

            }
      
            .white-navbar .container .nav button {
                right: 200px!important;
            }
        }
        
        
    }


    
}


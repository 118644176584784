@import "../../App.scss";

.values {
    //height: 570px;
    width: 100%;
    background: white;
    padding-top: 50px;
    //margin-bottom: 50px;
    height: 800px;
    @include tablet {
        height: 900px;
    }
    @include mobile {
        height: 600px;
    }
    @include phones {
        min-height: 1200px;
        margin-bottom: 0px;
    }
    

    .wrapper {
        width: 100%;
        padding-left: 10%;
        display: flex;
        flex-direction: column;
        gap: 29px;

        @include phones {
            padding-left: 10%;
            height: 100%;
            gap: 15px;
        }

        div {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            @include phones {
                align-items: center;
            }

            button {
                width: 144px;
                height: 42px;
                display: flex;
                padding: 12px 24px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 200px;
                border: 1px solid var(--Linear, #66DAFF);
                color: #4A4752;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 110%; /* 17.6px */
                background: #F8F7FA;
            }
            h3 {
                width: 373px;
                height: 108px;
                color: #4A4752;
                font-family: "Inter", sans-serif;
                font-size: 36px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 36px */
                letter-spacing: -1.44px;

                @include phones {
                    font-size: 30px;
                    width: 250px;
                }
            }
        }
        .list {
            display: flex;
            flex-direction: row;
            width: 90%;
            align-items: flex-start;
            gap: 16px;
            flex-wrap: wrap;

            @include phones {
                height: 950px;
                //background: red;
                width: 95%;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }

            .item {
                width: 299px;
                height: 222px;
                display: flex;
                padding: 24px 24px 45px 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                flex: 1 0 0;
                border-radius: 16px;
                background: #F9F8FC;
                @include tablet {
                    padding: 10px;
                }

                @include mobile {
                    gap: 2px;
                    padding: 10px;
                }

                @include phones {
                    width: 250px;
                    padding: 15px;
                    gap: 15px;
                }

                h5 {
                    align-self: stretch;
                    color: #26242F;
                    font-family: "Inter", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 132%; /* 26.4px */
                    letter-spacing: -0.4px;
                    @include tablet {
                        font-size: 16px;
                    }
                }
                p {
                    color: #262641;
                    font-family: "Inter", sans-serif;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 21px */
                    align-self: stretch;

                    @include tablet {
                        font-size: 10px;
                    }
                    @include mobile {
                        font-size: 8px;
                    }
                    @include phones {
                        font-size: 14px;
                    }
                }
            }

            .mobile-align {
                @include mobile {
                    width: 250px!important;
                    height: 100px;
            }
            }
        }

        .mobile-hide {
            @include mobile {
                display: none;
            }
        }
    }
}


@import "../../App.scss";

.foot-head {
    height: 900px;
   // background: gray;

   @include mobile {
    height: 800px;
   }
    

    .wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
        //justify-content: center;
        align-items: center;
        gap: 117px;
        padding: 8%;
        position: relative;

        .foot-bg0img {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                @include phones {
                    height: 70%;
                }
            }
        }

        .top {
            width: 620px;
            height: 154px;
            position: absolute;
            top: 200px;

            @include mobile {
                width: 100%;
                height: 300px;
                padding: 0px 5%;
            }

            p {
                color: #FFF;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 64px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 76.8px */
                letter-spacing: -2.56px;

                @include mobile {
                    font-size: 54px;
                }
                @include phones {
                    font-size: 44px;
                }

                span {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .bottom {
            position: absolute;
            bottom: -100px;
            display: flex;
            flex-direction: column;
            padding: 72px 144px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 60%;
            border-radius: 48px;
            background: #1B1924;

            @include mobile {
                bottom: -150px;
                height: 300px;
            }

            p {
                color: #BCB8C6;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 28.8px */
                letter-spacing: -0.48px;

                @include mobile {
                    font-size: 18px;
                    width: 200px;
                }
            }
            a {
                color: #FFF;
                font-family: "Inter", sans-serif;
                font-size: 96px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -1.92px;
                cursor: pointer;
                text-align: center;

                @include mobile {
                    font-size: 56px;
                    width: 200px;
                }
            }
        }
    }

}


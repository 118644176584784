@import "../../App.scss";

.white-foot-head {
    height: 900px;
    background: #FFF;
    
    .white-wrapper {
        display: flex;
        height: 100%;
        flex-direction: column;
        //justify-content: center;
        align-items: center;
        gap: 57px;
        padding: 8%;
        

        .white-top {
            width: 820px;
            height: 154px;

            @include mobile {
                width: 97%;
                height: 300px;
            }

            @include phones {
                height: 250px;
                width: 100%;
            }
            
            p {
                color: #0F0D18;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 64px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 76.8px */
                letter-spacing: -2.56px;

                @include mobile {
                    font-size: 58px;
                }
                @include phones {
                    font-size: 42px;
                }
            }
        }

        .white-bottom {
            display: flex;
            flex-direction: column;
            padding: 72px 144px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 48px;
            background: #1B1924;
            @include mobile {
                width: 70%;
                height: 380px;
                padding: 10px;
            }
            @include phones {
                width: 100%;
            }

            p {
                color: #BCB8C6;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 28.8px */
                letter-spacing: -0.48px;
                @include mobile {
                    width: 250px;
                }
                @include phones {
                    width: 200px;
                }
            }
            a {
                color: #FFF;
                font-family: "Inter", sans-serif;
                font-size: 96px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -1.92px;
                width: 300px;
                @include mobile {
                    font-size: 70px;
                    display: flex;
                    text-align: center;
                }
                @include phones {
                    font-size: 70px;
                }
           
            }
        }
    }

}
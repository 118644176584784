@import "../App.scss";

.homePage {
    //width: 90rem;
    min-height: 150vh;
    background: #0F0D18;
    mix-blend-mode: inherit; //luminosity;
    // background: linear-gradient(180deg, #7E5DEC 0%, rgba(126, 93, 236, 0.00) 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;

    @include tablet {
        max-width: 100vw;
    }

    @include mobile {

    }

    .bg-img {
        background-image: url("../image/back.png");
        background-size: contain;
        background-repeat: no-repeat;
    }

    
}


@import "../../App.scss";

.form {
    width: 827px;
    display: flex;
    justify-content: center;
    align-self: flex-start;
    margin-left: 10%;
    margin-bottom: 100px;
    @include tablet {
        width: 80%;
        margin-left: 10%;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        //background: yellow;

        input {
            display: flex;
            padding: 32px 0px;
            align-items: center;
            gap: 16px;
            align-self: stretch;
            border-bottom: 1px solid #9797A6;
            border-top: 0px;
            border-right: 0px;
            border-left: 0px;
            outline: none;
            color: #777192;
            text-align: justify;
            font-family: "Inter", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 22px */
            letter-spacing: -0.4px;

            @include phones {
                font-size: 14px;
            }
        }

        textarea {
            height: 178px;
            color: #777192;
            font-family: "Inter", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 22px */
            letter-spacing: -0.4px;
            border-top: 0px;
            border-right: 0px;
            border-left: 0px;
            text-align: justify;
            outline: none;
        }

        .budjet {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

            h2 {
                height: 29px;
                color: #0F0D18;
                font-family: "Inter", sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 28.8px */
                letter-spacing: -0.48px;
            }

            .price {
                display: flex;
                width: 827px;
                align-items: center;
                align-content: center;
                gap: 12px;
                flex-wrap: wrap;

                .price-item {
                    display: flex;
                    padding: 24px 32px;
                    align-items: center;
                    gap: 10px;
                    border-radius: 200px;
                    border: 1px solid #9797A6;
                    color: #0F0D18;
                    text-align: center;
                    font-family: "Inter", sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 110%; /* 22px */
                    letter-spacing: -0.4px;
                }
            }
        }
        div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            button {
                cursor: pointer;
                width: 25%;
                height: 66px;
                display: inline-flex;
                padding: 24px 32px;
                margin-top: 68px;
                outline: none;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 200px;
                border: 1px solid #7E5DEC;
                background: #7E5DEC;
                color: #FFF;
                text-align: center;
                font-family: "Inter", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 110%; /* 17.6px */
                @include mobile {
                    padding: 3% 5%;
                    width: 30%;
                }
                @include phones {
                    margin-top: 38px;
                    width: 40%;
                }
            }

            button:hover {
                background: black;
                color: white;
            }
            
      
                
                a.btn-feed {
                    background: black;
                    color: white;
                    font-size: 16px;
                    cursor: pointer;
                    width: 25%;
                    height: 66px;
                    border-radius: 200px;
                    padding: 24px 20px;
                    margin-top: 68px;
                    text-align: center;
                    @include mobile {
                        padding: 3% 5%;
                        width: 30%;
                    }
                    @include phones {
                        width: 38%;
                        height: 60px;
                        margin-top: 50px;
                        padding: 3% 3%;
                    }
                }

                .btn-feed:hover {
                    background: green;
                    color: white;
                }
                
            
        }

        
    }
}
@import "../../App.scss";

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 8%;
    width: 90%;
    height: 720px;
    // background-image: url("../image/background-img.png");
    // background-size: cover;
    // background-repeat: no-repeat;

    @include tablet {

    }

    @include mobile {
        padding-left: 0%;
    }
    @include phones {
        height: 720px;
        width: 100%;
    }

    .text-box {
        width: 477px;
        height: 192px;
        color: #BCB8C6;
        text-align: left;
        font-family: "Inter", sans-serif;
        font-size: 96px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 96px */
        letter-spacing: -3.84px;
        margin: 42px 0;

        @include mobile {
            font-size: 66px;
            width: 400px;
            margin-left: 15%;
            margin-bottom: 0px;
        }
        @include phones {
            width: 85%;
            padding-left: 0%;
            //height: 100px;
            text-align: center;
            align-self: flex-start;
            height: 132px;
            margin-top: 0px;
            font-size: 56px;
            margin-left: 10px;
            margin-bottom: 42px;
        }

        span {
            color: #FFF;
            font-family: "Inter", sans-serif;
            font-size: 76px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -3.84px;

            @include mobile {
                font-size: 76px;
            }
            @include phones {
                font-size: 56px;
            }
        }
    }

    a.btn {
        display: inline-flex;
        align-items: flex-start;
        gap: -1px;
        width: 286px;
        height: 80px;
        display: flex;
        margin-left: -100px;
        align-self: center;
        justify-content: center;
        cursor: pointer;
        @include tablet {
            align-self: center;
        }
        @include mobile {
            margin-left: 5%;
            //align-self: flex-start;
        }

        @include phones {
            width: 251px;
            align-self: flex-start;
            height: 50px;
            margin-left: 5%;
        }


        button {
            display: flex;
            padding: 0px 45px;
            justify-content: center;
            align-items: center;
            border-radius: 200px;
            border: 1px solid rgba(255, 255, 255, 0.40);
            color: #FFF;
            text-align: center;
            font-family: "Inter", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 22px */
            letter-spacing: -0.4px;
            background: transparent;
            width: 207px;
            height: 80px;
            cursor: pointer;
            @include phones {
                height: 100%;
                padding: 0px 14px;
                margin-left: 15%;
                width: 150px;
            }
        }
        svg {
            width: 80px;
            height: 80px;
            stroke-width: 1px;
            stroke: rgba(255, 255, 255, 0.40);
            display: none;
            @include mobile {
                display: none;
            }
            @include phones {
                height: 50px;
                
            }
        }
    }

    .bottom-btn {
        width: 550px;
        height: 240px;
        margin-top: 95px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        gap: 24px;

        
        @include mobile {
            width: 90%;
            height: 350px;
            margin-left: 5%;
            margin-top: 95px;    
        }
        @include phones {
            height: 390px;
            gap: 20px;
            margin-top: 50px;
            align-items: flex-start;
        }

        p {
            color: #FFF;
            font-family: "Inter", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 36px */
            letter-spacing: -0.48px;

            @include mobile {
                font-size: 19px;
            }
            @include phones {
                padding-left: 5%;
                font-size: 16px;
            }

            span {
                color: #BCB8C6;
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                letter-spacing: -0.48px;
            }
        }

        a { 
            display: flex;
            padding: 16px 16px 16px 32px;
            justify-content: center;
            align-items: center;
            gap: 32px;
            cursor: pointer;
            border-radius: 200px;
            border: 1px solid rgba(255, 255, 255, 0.40);
            color: #FFF;
            text-align: center;
            font-family: "Inter", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 22px */
            letter-spacing: -0.4px;
            @include phones {
                height: 60px;
                width: 180px;
                margin-left: 15%;
                text-align: unset;
                padding: 5px 2%;
                align-items: center;
                font-size: 15px;
                gap: 15px;
            }
        }

        .svg {
            display: flex;
            padding: 12px;
            justify-content: center;
            cursor: pointer;
            align-items: center;
            gap: 10px;
            border-radius: 200px;
            background: linear-gradient(98deg, #FC63FF -82.69%, #8E5BEC 47.23%, #66DAFF 194.91%);
            @include phones {
                padding: 4px;
                height: 42px;
                width: 42px;
                margin-left: unset!important;
                
            }
            svg {
                color: white;
                
            }
        }

    }
}


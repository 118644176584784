@import "../../App.scss";

.choose {
    width: 100%;
    background: white;
    padding-left: 8%;
    
    .c-wrapper {
        width: 90%;
        height: 400px;
        display: flex;
        flex-direction: column;
        background: white;
        //margin-top: 100px;

        @include mobile {
            height: 300px;
            padding-top: 40px;
            gap: 20px;
        }
        @include phones {
            height: 500px;
        }

        button {
            width: 180px;
            height: 50px;
            color: #4A4752;
            text-align: center;
            font-family: "Inter", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%; /* 17.6px */
            display: inline-flex;
            padding: 12px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 200px;
            border: 1px solid var(--Linear, #66DAFF);
            background: #F8F7FA;
            @include tablet {
                width: 200px;
                height: 48px;
            }

            @include phones {
                height: 47px;
                width: 190px;
                font-size: 14px;
            }
        }
        p {
            color: #0A0816;
            font-family: "Inter", sans-serif;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 50.4px */
            letter-spacing: -0.72px;
            width: 80%;

            @include tablet {
                width: 100%;
                font-size: 26px;
            }
            @include mobile {
                font-size: 20px;
            }
        }
    }
}
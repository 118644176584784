html {
   scroll-snap-type: y mandatory;
   scroll-behavior: smooth;
  //overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

body {
  background-color: #0c0c1d;
  color: lightgray;
  
}

.app {
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden; 
 // overflow: hidden;
  //background-color: red;
}

a {
  text-decoration: none;
  color: inherit;
}

// section {
//   height: 100vh; //50vh
//   scroll-snap-align: center;
//   background-color: green;
// }

@mixin phones {
  @media (max-width: 435px) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1440px) {
    @content;
  }
}

//2hrs, 20mins

/*

//Framer Motion Testing Styles :
.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blue;

  .box {
    background-color: white;
    height: 200px;
    width: 200px;
  }
}

*/

